import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortfolioComponent } from './portfolio.component';
import { PortfolioRoutingModule } from './portfolio-routing.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    PortfolioRoutingModule,
    NgxUiLoaderModule,
    TranslateModule
  ],
  exports: [
   PortfolioComponent
  ],
  declarations: [
   PortfolioComponent
  ],
  providers: [
  ],
})
export class PortfolioModule { }
