<div class="portfolio">
   <div class="section-title">
      <h2 class="title">{{ 'PORTFOLIO.TITLE' | translate }}</h2>
      <p>{{ 'PORTFOLIO.TITLE_DESCRIPTION' | translate }}</p>
   </div>
   <div class="section-wrapper">

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_1_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_1_TITLE' | translate }}</h3>
         <div class="card-button"><a href="https://asbunbanquetes.com/"
               target="_blank">{{ 'PORTFOLIO.CARD_VIEW_SITE' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_2_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_2_TITLE' | translate }}</h3>
         <div class="card-button"><a
               href="https://play.google.com/store/apps/details?id=com.eightkilobytes.runfromthegiantball"
               target="_blank">{{ 'PORTFOLIO.CARD_VIEW_GAME' | translate }}</a>
         </div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_3_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_11_TITLE' | translate }}</h3>
         <div class="card-button"><a href="https://mtech-systems.com/products/amino"
               target="_blank">{{ 'PORTFOLIO.CARD_VIEW_SITE' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_3_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_3_TITLE' | translate }}</h3>
         <div class="card-button"><a href="https://mtech-systems.com/products/sonar-echo"
               target="_blank">{{ 'PORTFOLIO.CARD_VIEW_SITE' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_3_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_4_TITLE' | translate }}</h3>
         <div class="card-button"><a href="https://mtech-systems.com/products/sonar-echo"
               target="_blank">{{ 'PORTFOLIO.CARD_VIEW_SITE' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_9_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_5_TITLE' | translate }}</h3>
         <div class="card-button"><a href="#"
               target="_blank">{{ 'PORTFOLIO.CARD_VIEW_DETAILS' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_4_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_10_TITLE' | translate }}</h3>
         <div class="card-button"><a href="#"
               target="_blank">{{ 'PORTFOLIO.CARD_VIEW_DETAILS' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_5_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_6_TITLE' | translate }}</h3>
         <div class="card-button"><a href="#"
               target="_blank">{{ 'PORTFOLIO.CARD_VIEW_DETAILS' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_10_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_7_TITLE' | translate }}</h3>
         <div class="card-button"><a href="#"
               target="_blank">{{ 'PORTFOLIO.CARD_VIEW_DETAILS' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_11_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_8_TITLE' | translate }}</h3>
         <div class="card-button"><a href="#"
               target="_blank">{{ 'PORTFOLIO.CARD_COMING_SOON' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_7_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_9_TITLE' | translate }}</h3>
         <div class="card-button"><a href="#"
               target="_blank">{{ 'PORTFOLIO.CARD_COMING_SOON' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_12_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_9_TITLE' | translate }}</h3>
         <div class="card-button"><a href="#"
               target="_blank">{{ 'PORTFOLIO.CARD_COMING_SOON' | translate }}</a></div>
      </div>

      <div class="portfolio-card">
         <div class="card-icon">{{ 'PORTFOLIO.CARD_8_WHAT_I_DID' | translate }}</div>
         <h3 class="card-title">{{ 'PORTFOLIO.CARD_9_TITLE' | translate }}</h3>
         <div class="card-button"><a href="#"
               target="_blank">{{ 'PORTFOLIO.CARD_COMING_SOON' | translate }}</a></div>
      </div>

   </div>
</div>