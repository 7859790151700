import { Component, Renderer2, HostListener, OnInit } from '@angular/core';
import {
  Router,
  NavigationStart,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from '@angular/router';
import {
  trigger,
  state,
  transition,
  style,
  animate,
  group,
} from '@angular/animations';
import {
  faGithub,
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state(
        'close',
        style({
          opacity: 0,
          visibility: 'hidden',
        })
      ),
      state(
        'open',
        style({
          opacity: 1,
          visibility: 'visible',
        })
      ),
      transition(':enter', animate(0)),
      transition('close <=> open', [animate('.35s')]),
    ]),
    trigger('slideAnimation', [
      state(
        'open',
        style({
          transform: 'translate3d(0, 0, 0)',
          visibility: 'visible',
          display: 'block',
        })
      ),
      transition('open <=> *', [
        animate('.35s cubic-bezier(0.645, 0.045, 0.355, 1)'),
      ]),
      state(
        'close',
        style({
          transform: 'translate3d(100%, 0, 0)',
          visibility: 'hidden',
          display: 'none',
        })
      ),
      transition('close <=> open', [
        group([
          animate(
            '.35s cubic-bezier(0.645, 0.045, 0.355, 1)',
            style({
              transform: 'translate3d(100%, 0, 0)',
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es/) ? browserLang : 'en');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(
            document.querySelector('.header-area'),
            this.previousUrl
          );
        }
        const currentUrlSlug = event.url.slice(1);
        if (currentUrlSlug) {
          this.renderer.addClass(
            document.querySelector('.header-area'),
            currentUrlSlug
          );
        }
        this.previousUrl = currentUrlSlug;
      }
    });
    this.getScreenSize();
  }

  previousUrl: string;
  faGithub = faGithub;
  faTwitter = faTwitter;
  faLinkedinIn = faLinkedinIn;
  faFacebookF = faFacebookF;

  scrHeight: any;
  scrWidth: any;
  isMobile = false;
  name: string;
  locationRoute: string;
  isOpen = false;
  english = false;
  state: 'open' | 'close' = 'close';

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.ngxService.start();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.ngxService.stop();
      }
    });
  }

  slide() {
    this.isOpen = !this.isOpen;
  }

  changeLanguage(lang: string) {
    this.english = !this.english;
    this.translate.use(lang);
  }

  overlayDone() {
    if (this.isOpen) {
      this.state = 'open';
    } else {
      this.state = 'close';
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    /* this.scrHeight = window.innerHeight; */
    this.scrWidth = window.innerWidth;
    if (this.scrWidth <= '960') {
      return (this.isMobile = true);
    } else {
      return (this.isMobile = false);
    }
  }
}
