import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  previousUrl: string;

  constructor(
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    public translate: TranslateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/about') {
          return this.router.navigateByUrl('/about/main-skills');
        }
      }
    });
  }

  ngOnInit() {
    this.ngxLoader.startLoader('about');
  }

  stopLoader() {
    this.ngxLoader.stopLoader('about');
  }
}
