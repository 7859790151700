import { Component, OnInit } from '@angular/core';
import { textFadeInOut } from '../../animations/animation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [textFadeInOut]
})
export class HomeComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  state: string;
  numberService = 0;
  serviceText: string;
  whatIdo = [
    this.translate.instant('HOME.FRONT_END_DEVELOPER'),
    this.translate.instant('HOME.JS_DEVELOPER'),
    this.translate.instant('HOME.APP_DEVELOPER'),
    this.translate.instant('HOME.UI_DEVELOPER'),
    this.translate.instant('HOME.BUILT_WITH_LOVE'),
    this.translate.instant('HOME.THANKS_FOR_YOUR_VISIT')
  ];

  ngOnInit() {
    this.changeText();
    setInterval(() => {
      this.state = 'fadeIn';
      this.changeText();
      this.numberService++;
      if (this.numberService >= this.whatIdo.length) {
        this.numberService = 0;
      }
      setTimeout(() => {
        this.state = 'fadeOut';
      }, 3800);
    }, 4000);
  }

  changeText() {
    this.serviceText = this.whatIdo[this.numberService];
  }

}
