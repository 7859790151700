<div class="service">
   <div class="section-title">
      <h2 class="title">{{ 'SERVICE.TITLE' | translate }}</h2>
      <p>{{ 'SERVICE.TITLE_DESCRIPTION' | translate }}</p>
   </div>
   <div class="section-wrapper">

      <div class="service-card">
         <div class="card-icon">
            <fa-icon [icon]="faAngular"></fa-icon>
         </div>
         <h3 class="card-title">{{ 'SERVICE.CARD_1_TITLE' | translate }}</h3>
         <p class="card-description">{{ 'SERVICE.CARD_1_DESCRIPTION' | translate }}
         </p>
      </div>

      <div class="service-card">
         <div class="card-icon">
            <fa-icon [icon]="faAndroid"></fa-icon>
         </div>
         <h3 class="card-title">{{ 'SERVICE.CARD_2_TITLE' | translate }}</h3>
         <p class="card-description">{{ 'SERVICE.CARD_2_DESCRIPTION' | translate }}</p>
      </div>

      <div class="service-card">
         <div class="card-icon">
            <fa-icon [icon]="faTachometerAlt"></fa-icon>
         </div>
         <h3 class="card-title">{{ 'SERVICE.CARD_3_TITLE' | translate }}</h3>
         <p class="card-description">{{ 'SERVICE.CARD_3_DESCRIPTION' | translate }}<a
               href="https://tools.pingdom.com">{{ 'SERVICE.CARD_3_DESCRIPTION_LINK' | translate }}</a></p>
      </div>

      <div class="service-card">
         <div class="card-icon">
            <fa-icon [icon]="faCss3"></fa-icon>
         </div>
         <h3 class="card-title">{{ 'SERVICE.CARD_4_TITLE' | translate }}</h3>
         <p class="card-description">{{ 'SERVICE.CARD_4_DESCRIPTION' | translate }}</p>
      </div>

      <div class="service-card">
         <div class="card-icon">
            <fa-icon [icon]="faSearch"></fa-icon>
         </div>
         <h3 class="card-title">{{ 'SERVICE.CARD_5_TITLE' | translate }}</h3>
         <p class="card-description">{{ 'SERVICE.CARD_5_DESCRIPTION' | translate }}</p>
      </div>

      <div class="service-card">
         <div class="card-icon">
            <fa-icon [icon]="faChartBar"></fa-icon>
         </div>
         <h3 class="card-title">{{ 'SERVICE.CARD_6_TITLE' | translate }}</h3>
         <p class="card-description">
            {{ 'SERVICE.CARD_6_DESCRIPTION' | translate }}</p>
      </div>
   </div>
</div>