<div class="container">
   <div class="welcome-wrapper">
      <div class="welcome">
         <span>{{ 'HOME.WELCOME' | translate }}
         </span>
         <div class="content">
            <div class="content-container">
               <ul class="content-container__list">
                  <li class="content-container__list-item">{{ 'HOME.WORLD' | translate }}</li>
                  <li class="content-container__list-item">{{ 'HOME.USERS' | translate }}</li>
                  <li class="content-container__list-item">{{ 'HOME.RECRUITERS' | translate }}</li>
                  <li class="content-container__list-item">{{ 'HOME.EVERYBODY' | translate }}</li>
               </ul>
            </div>
         </div>
      </div>

      <h1 class="title">
         {{ 'HOME.HI_IM_ROBERTO' | translate }}
      </h1>
      <span class="text-animation"
            [@textFadeInOut]="state">{{serviceText | translate}}</span>
      <h2>{{ 'HOME.BASED_IN_AUSTRALIA' | translate }}</h2>
      <h3>
         <span>{{ 'HOME.MADE' | translate }}</span>
         <span>{{ 'HOME.IN' | translate }}</span>
         <span>{{ 'HOME.MEXICO' | translate }}</span>
      </h3>
   </div>
   <div class="last-update">{{ 'HOME.LAST_UPDATE' | translate }}</div>
</div>