import { Component, OnInit } from '@angular/core';

import {
  faAngular,
  faCss3,
  faAndroid,
} from '@fortawesome/free-brands-svg-icons';

import {
  faTachometerAlt,
  faChartBar,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
})
export class ServiceComponent {
  constructor(public translate: TranslateService) {}

  faCss3 = faCss3;
  faAngular = faAngular;
  faAndroid = faAndroid;
  faTachometerAlt = faTachometerAlt;
  faChartBar = faChartBar;
  faSearch = faSearch;
}
