import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomeModule } from './modules/general/home/home.module';
import { AboutModule } from './modules/general/about/about.module';
import { ServiceModule } from './modules/general/service/service.module';
import { PortfolioModule } from './modules/general/portfolio/portfolio.module';
import { SlideshowModule } from 'ng-simple-slideshow';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#0c87f2',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 110,
  bgsType: 'three-strings',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#0c87f2',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'three-strings',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#3f9cff',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '[ R ]',
  textColor: '#0c87f2',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    HomeModule,
    AboutModule,
    ServiceModule,
    PortfolioModule,
    SlideshowModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
