<div class="app">
     <div class="overlay"
          [@overlayAnimation]="isOpen ? 'open' : 'close'"
          (@overlayAnimation.done)="overlayDone()"
          (click)="slide()">
     </div>
     <header class="header-area"
             [class.mobile]="isMobile">
          <nav class="mainmenu-nav">
               <ul *ngIf="!isMobile"
                   class="mainmenu">
                    <li><a routerLink="/home"
                            routerLinkActive="isActive home">{{ 'HOME.HOME' | translate }}</a></li>
                    <li><a routerLink="/about"
                            routerLinkActive="isActive">{{ 'HOME.ABOUT' | translate }}</a></li>
                    <li><a routerLink="/service"
                            routerLinkActive="isActive">{{ 'HOME.SERVICE' | translate }}</a></li>
                    <li><a routerLink="/portfolio"
                            routerLinkActive="isActive">{{ 'HOME.PORTFOLIO' | translate }}</a></li>
                    <li><a routerLink="/contact"
                            routerLinkActive="isActive">{{ 'HOME.CONTACT' | translate }}</a></li>
               </ul>
               <ul class="mainmenu"
                   *ngIf="isMobile"
                   [@slideAnimation]="state"
                   (@slideAnimation.start)="$event.element.style.display = 'block'">
                    <li><a routerLink="/home"
                            routerLinkActive="isActive home"
                            (click)="slide()">{{ 'HOME.HOME' | translate }}</a></li>
                    <li><a routerLink="/about"
                            routerLinkActive="isActive"
                            (click)="slide()">{{ 'HOME.ABOUT' | translate }}</a></li>
                    <li><a routerLink="/service"
                            routerLinkActive="isActive"
                            (click)="slide()">{{ 'HOME.SERVICE' | translate }}</a></li>
                    <li><a routerLink="/portfolio"
                            routerLinkActive="isActive"
                            (click)="slide()">{{ 'HOME.PORTFOLIO' | translate }}</a></li>
                    <li><a routerLink="/contact"
                            routerLinkActive="isActive"
                            (click)="slide()">{{ 'HOME.CONTACT' | translate }}</a></li>
               </ul>
               <ul class="mainmenu social">
                    <li><a href="https://github.com/gayolr"
                            target="_blank">
                              <fa-icon [icon]="faGithub"></fa-icon>
                         </a></li>
                    <li><a href="https://www.linkedin.com/in/gayolr"
                            target="_blank">
                              <fa-icon [icon]="faLinkedinIn"></fa-icon>
                         </a></li>
                    <li><a href="https://www.facebook.com/GayolR"
                            target="_blank">
                              <fa-icon [icon]="faFacebookF"></fa-icon>
                         </a></li>
                    <!-- <li><a href="https://twitter.com/GayolR"
                            target="_blank">
                              <fa-icon [icon]="faTwitter"></fa-icon>
                         </a></li> -->
                    <li>
                         <button #lang
                                 [value]="this.english ?  'es' : 'en'"
                                 (click)="changeLanguage(lang.value)">
                              {{ this.english ? ('HOME.SPANISH' | translate) : ('HOME.ENGLISH' | translate) }}
                         </button>
                    </li>
               </ul>
               <div *ngIf="!this.isOpen && this.isMobile"
                    class="menu-wrapper"
                    (click)="slide()">
                    <div class="menu-button"
                         (click)="slide()">
                         <svg stroke="currentColor"
                              fill="none"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg">
                              <line x1="3"
                                    y1="12"
                                    x2="21"
                                    y2="12"></line>
                              <line x1="3"
                                    y1="6"
                                    x2="21"
                                    y2="6"></line>
                              <line x1="3"
                                    y1="18"
                                    x2="21"
                                    y2="18"></line>
                         </svg>
                    </div>
               </div>
               <div *ngIf="this.isOpen && this.isMobile"
                    class="menu-close"
                    (click)="slide()">
                    <svg stroke="currentColor"
                         fill="none"
                         stroke-width="2"
                         viewBox="0 0 24 24"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         height="1em"
                         width="1em"
                         xmlns="http://www.w3.org/2000/svg">
                         <line x1="18"
                               y1="6"
                               x2="6"
                               y2="18"></line>
                         <line x1="6"
                               y1="6"
                               x2="18"
                               y2="18"></line>
                    </svg>
               </div>
          </nav>
     </header>

     <main>
          <div class="content">
               <ngx-ui-loader></ngx-ui-loader>
               <router-outlet></router-outlet>
          </div>
     </main>

</div>