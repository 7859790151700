import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './about.component';

const routes: Routes = [
  {
    path: '', component: AboutComponent, children: [
      {
        path: '',
        loadChildren: () => import(`./main-skill/main-skill.module`)
          .then(m => m.MainSkillModule)
      },
      {
        path: 'main-skills',
        loadChildren: () => import(`./main-skill/main-skill.module`)
          .then(m => m.MainSkillModule)
      },
      {
        path: 'experience',
        loadChildren: () => import(`./experience/experience.module`)
          .then(m => m.ExperienceModule)
      },
      {
        path: 'education-certification',
        loadChildren: () => import(`./education-certification/education-certification.module`)
          .then(m => m.EducationCertificationModule)
      },
      {
        path: 'extra',
        loadChildren: () => import(`./extra/extra.module`)
          .then(m => m.ExtraModule)
      },
      {
        path: '**',
        loadChildren: () => import(`./main-skill/main-skill.module`)
          .then(m => m.MainSkillModule)
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutRoutingModule { }
