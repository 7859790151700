import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServiceComponent } from './service.component';
import { ServiceRoutingModule } from './service-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ServiceRoutingModule,
    FontAwesomeModule,
    TranslateModule
  ],
  exports: [
    ServiceComponent
  ],
  declarations: [
    ServiceComponent
  ],
  providers: [
  ],
})
export class ServiceModule { }
