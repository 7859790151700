import {
   trigger,
   transition,
   state,
   style,
   animate
 } from '@angular/animations';

export const textFadeInOut = trigger('textFadeInOut', [
   state(
     'fadeIn',
     style({
       transform: 'translateY(0px)',
       opacity: '1',
     })
   ),
   state(
     'fadeOut',
     style({
      transform: 'translateY(60px)',
       opacity: '0',
     })
   ),
   transition('fadeIn <=> fadeOut', animate('200ms ease-out')),
 ]);
