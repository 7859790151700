<div class="about">
   <div class="section-photo">
      <div class="photo-wrapper">
         <ngx-ui-loader [loaderId]="'about'"></ngx-ui-loader>
         <picture>
            <source media="(max-width: 960px)"
                    srcset="https://robertogayol.8kilobytes.com/assets/img/gayol-about-mobile.webp"
                    type="image/webp">
            <source media="(max-width: 960px)"
                    srcset="https://robertogayol.8kilobytes.com/assets/img/gayol-about-mobile.jpg"
                    type="image/jpeg">
            <source media="(max-width: 961px)"
                    srcset="https://robertogayol.8kilobytes.com/assets/img/gayol-about-pc.webp"
                    type="image/webp">
            <source media="(max-width: 961px)"
                    srcset="https://robertogayol.8kilobytes.com/assets/img/gayol-about-pc.jpg"
                    type="image/jpeg">
            <img class="about-photo"
                 (load)="stopLoader()"
                 src="https://robertogayol.8kilobytes.com/assets/img/gayol-about-pc.webp"
                 alt="About Gayol Photo">
         </picture>
      </div>
   </div>

   <div class="section-content">
      <div class="section-title">
         <h1 class="title">{{ 'ABOUT.ABOUT_ME' | translate }}</h1>
         <p>{{ 'ABOUT.ABOUT_DESCRIPTION' | translate }}</p>
      </div>
      <div class="section-skills__wrapper">
         <div class="mainmenu-nav">
            <ul class="mainmenu">
               <li><a routerLink="/about/main-skills"
                     routerLinkActive="isActive">{{ 'ABOUT.TITLE_MAIN_SKILLS' | translate }}</a></li>
               <li><a routerLink="/about/experience"
                     routerLinkActive="isActive">{{ 'ABOUT.TITLE_EXPERIENCE' | translate }}</a></li>
               <li><a routerLink="/about/education-certification"
                     routerLinkActive="isActive">{{ 'ABOUT.TITLE_EDUCATION_CERTIFICATION' | translate }}</a></li>
               <li><a routerLink="/about/extra"
                     routerLinkActive="isActive">{{ 'ABOUT.TITLE_EXTRA' | translate }}</a></li>
            </ul>
         </div>
         <div class="section-skills">
            <router-outlet></router-outlet>
         </div>
      </div>
   </div>
</div>